import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions'; //Import your actions
import 'materialize-css';
import '../../styles/report.scss';

import AddReportTest from './AddReportTest';
import EditReportNote from './EditReportNote';
import ImageUpload from './ImageUpload';

class NodeWorkbench extends React.Component {

  constructor() {
    super();
    this.state = {
      optionSelected: null
    };
  }

  addNote() {
    let id = Date.now();

    let noteObj = {
      id: id,
      report_id: this.props.report.id,
      note_type: 'note',
      content: '',
      component_id: this.props.component.id,
      iAm: 'note',
      breadcrumbs: (
        this.props.component.id === '-1' ? 
        `${this.props.report.id}.components.log.nodes.${new Date().toLocaleString().split(',')[0]}.${id}` :
        `${this.props.report.id}.components.${this.props.component.id}.notes.${id}`
      ),
      synced: false
    };

    this.props.updateReport(
      this.buildScaffold(noteObj),
      null,
      () => this.props.switchFocus(noteObj, this.props.component)
    );
  }

  buildScaffold(node) {
    let breadcrumbs = node.breadcrumbs.split('.');
    let reportId = breadcrumbs.reverse().pop();
    let reportScaffold = {
      id: reportId,
      timestamp: Math.floor(Date.now()/1000),
      ...breadcrumbs.reduce((val, key) => ({[key]: val}), {...node})
    };
   
    return reportScaffold;
  }

  close() {
    this.setState({optionSelected: null});
    this.props.close();
  }

  render() {
    if (this.props.component) {
      switch (this.state.optionSelected) {

        case 'test':
          return <AddReportTest
            component={this.props.component}
            close={() => this.close()}
          />
        
        case 'note':
          return <EditReportNote
            node={this.props.node}
            switchFocus={(node) => this.props.switchFocus(node, this.props.component)}
            close={() => this.close()}
          />

        case 'image':
          return <ImageUpload
            component={this.props.component}
            switchFocus={(node) => this.props.switchFocus(node)}
            close={() => this.close()}
          /> 

        default:
          if (this.props.node) {
            return <EditReportNote
              node={this.props.node}
              switchFocus={(node) => this.props.switchFocus(node, this.props.component)}
              close={() => this.close()}
            />
          } else {
            return (
              <div class={`report-modal`} onClick={() => this.close()}>
                <button class="create-node" data-node="test" onClick={(e) => {
                  e.stopPropagation();
                  this.setState({optionSelected: 'test'})
                }}>
                  Add Test
                </button>
                <button class="create-node" data-node="note" onClick={(e) => {
                  e.stopPropagation();
                  this.addNote();
                }}>
                  Add Note
                </button>
                <button class="create-node" data-node="image" onClick={(e) => {
                  e.stopPropagation();
                  this.setState({optionSelected: 'image'})
                }}>
                  Upload Image
                </button>
              </div>
            )
          }
      }
    } else { return false }
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    report: state.report,
    tests: state.testFields
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NodeWorkbench);
