const merge = require('deepmerge');

const initialState = {
  authToken: localStorage.getItem('authToken') || null,
  clientVersion: '1.6.0'
}

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return {
        ...state,
        initialLoadComplete: action.state
      }
    case 'FETCH_BEGIN':
      return {
        ...state,
        loading: true,
        // error: null
      };
    case 'FETCH_SUCCESS':
      console.log('fetch completed with response', action.response);

      return {
        ...state,
        loading: false
      }
    case 'FETCH_FAILURE':
      console.log('oops fetch failed', action.payload);
      let errorText = action.payload.status || action.payload.toString();

      if (action.payload.status === 401) {
        console.log('got a 401');
        if (state.authToken) {
          errorText = "Your session has expired. Please log in to resume using Wave."
        } else {
          console.log('login failed');
          errorText = "Incorrect login credentials. Please try again, or contact Toby to reset your account password if necessary."
        }
      }

      return {
        ...state,
        loading: false,
        error: errorText,
        authToken: action.payload.status === 401 ? null : state.authToken
      };
    case 'FETCH_SESSION_SUCCESS':
      localStorage.setItem('user', action.payload.user.username);
      localStorage.setItem('authToken', action.payload.user.token);

      return {
        ...state,
        loading: false,
        authToken: action.payload.user.token,
        user: {
          id: action.payload.user.id,
          username: action.payload.user.username,
          employee: action.payload.user.employee,
          email: action.payload.user.email,
          tempPass: action.payload.user.temppass,
          alert: action.payload.user.alert,
          sessionCreated: Date.now(),
          managing: action.payload.user.managing,
        },
        debug: action.payload.user.debug,
        lastLogged: action.payload.user.username,
      };
    case 'FETCH_ACCOUNTS_SUCCESS':
      return {
        ...state,
        accounts: action.payload,
        loading: false
      };
    case 'FETCH_REPORT_SUCCESS':

      // // load existing report from localStorage
      // let localReport = localStorage.getItem(`report-${action.report.hash_id}`);
      //
      // if (localReport) {
      //   localReport = JSON.parse(localReport);
      //   localReport['local'] = true;
      //
      //   action.report = action.report.last_edit > localReport?.last_edit ? action.report : localReport;
      // };

      return {
        ...state,
        report: action.report
      }
    case 'FETCH_REPORT_DRAFTS_SUCCESS':
      console.log('found', action.drafts.length, 'report drafts');
      return {
        ...state,
        drafts: action.drafts
      }
    case 'TERM_SEARCHED':
      return {
        ...state,
        pastSearches: (state.pastSearches || []).concat(action.term).pop() //yeah i said it fuck you
      }
    case 'FETCH_INFO_SUCCESS':
      let searched = {};
      if (action.info.model) {
        searched = {name: action.info.model, id: action.info.id, type: 'p'};
      } else if (action.info.name) {
        searched = {name: action.info.name, id: action.info.id, type: 'a'};
      }

      let pastSearches = state.pastSearches;
      //check if searched is valid and pastSearches doesnt contain searched
      if (searched.name && !(state.pastSearches || []).map(s => s.name)?.includes(searched?.name)) { //woah homie calm down
        //pop first items from pastSearches if length over 5
        if (pastSearches?.length > 3) {
          pastSearches = pastSearches.slice(pastSearches.length-3, pastSearches.length);
        }
        pastSearches = (pastSearches || []).concat(searched)
      }

      return {
        ...state,
        info: action.info,
        loading: false,
        pastSearches: pastSearches
      };
    case 'UPDATE_REPORT_TEMPLATE':
      // called when edit has been made to report template via lookup
      return {
        ...state,
        info: {
          ...state.info,
          reportTemplate: action.payload
        }
      };
    case 'CLEAR_INFO':
      return {
        ...state,
        info: null
      };
    case 'CLEAR_SESSION':
      console.log('setting last logged', state.lastLogged);
      localStorage.removeItem('authToken');

      return {
        // ...state,
        loading: false,
        authToken: null,
        user: null,
        initialLoadComplete: false,
        error: null,
        lastLogged: state.lastLogged,
        drafts: state.drafts,
        clientVersion: state.clientVersion
      };
    // case 'NEW_REPORT':
    //   let events = [...state.events];
    //   let eventObj = events.filter(e => (`${e.customer_id}` === action.payload.customer.id && e.status !== 'finished'))[0]
    //   if (eventObj) {
    //     eventObj.report_id = action.payload.id
    //     eventObj.status = eventObj.status === 'overdue' ? 'overdue' : 'unfinished'
    //   }
    //
    //   // console.log(events);
    //
    //   return {
    //     ...state,
    //     loading: false,
    //     report: action.payload,
    //     drafts: (state.drafts || []).concat(action.payload),
    //     events: events
    //   };

    case 'UPDATE_REPORT':
      // let report = {...state.report, ...action.payload}; // shallow merge
      // console.log('okay check this out', action.payload, state.report, report);
      const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

      let report = merge(state.report, action.payload, {arrayMerge: overwriteMerge}) // deep merge

      console.log('updating report:', action.payload, state.report, report);

      // since deepmerge wont overwrite ['fart'] with [] we gotta do some shit
      if (action.payload.recipients) {
        report.recipients = action.payload.recipients;
      }

      if (action.local) {
        localStorage.setItem(`report-${report.hash_id}`, JSON.stringify(report));
      }

      return {
        ...state,
        report: report,
        drafts: (state.drafts || []).filter(r => r.id !== state.report.id).concat(report)
      }
    case 'LOAD_TEST_HISTORY':
      let history = (state.testHistory || []).filter(node => !(node.component === action.component && node.test == action.test));
      let newHistoryNode = {
        component: action.component,
        test: action.test,
        history: action.history
      };
      history.push(newHistoryNode)
      return {
        ...state,
        testHistory: history,
        loading: false,
        // error: null,
      }
    case 'EXIT_REPORT':
      return {
        ...state,
        drafts: (state.drafts || []).filter(r => r.id !== state.report.id).concat(state.report),
        loading: false,
        report: null,
        testHistory: null
      };
    case 'REPORT_SUBMITTED':
      return {
        ...state,
        loading: false,
        report: null,
        // drafts: [...state.drafts.filter(r => r.id !== state.report.id)],
        // reportHistory: [...state.reportHistory, state.report]
      };
    case 'DELETE_REPORT_SUCCESS':
      console.log('deleting report', action.payload);
      return {
        ...state,
        drafts: (state.drafts || []).filter(r => r.id !== action.payload),
        loading: false,
        report: null
      };
    case 'FETCH_CALENDAR_SUCCESS':
      console.log('finished calendar fetch');
      return {
        ...state,
        loading: false,
        serviceableAccounts: action.payload.serviceableAccounts,
        managedAccounts: action.payload.managedAccounts,
        events: action.payload.events
      };
    case 'NEW_EVENT':
      return {
        ...state,
        events: (state.events || []).concat(action.payload)
      };
    case 'UPDATE_EVENT':
      console.log('CAUGHT THE BALL', action.payload);
      return {
        ...state,
        events: state.events.filter(e => e.id !== action.payload.id).concat(action.payload)
      };
    case 'DESTROY_EVENT':
      console.log('triggered destroy reducer');
      return {
        ...state,
        events: state.events.filter(e => e.id !== action.eventId)
      }
    case 'FETCH_PRODUCTS_SUCCESS':
      return {
        ...state,
        loading: false,
        products: action.payload
      };
    case 'UPDATE_ORDER':
      // console.log('saving order', action.order);
      return {
        ...state,
        order: action.order
      };
    case 'OFFLINE_REPORT_DATA':
      return {
        ...state,
        offlineTemplates: action.reports
      }
    case 'OFFLINE_REPORT_SUBMIT':
      return {
        ...state,
        offlineSubmitted: state.offlineSubmitted.push(action.payload)
      }
    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null
      }
    case 'TEST_ERROR':
      return {
        ...state,
        error: 'egg'
      }
    case 'REPORT_HISTORY':
      console.log('reports going in the fridge', action.reports);
      return {
        ...state,
        loading: false,
        reportHistory: action.reports
      }
    case 'ORDER_HISTORY':
      console.log('orders going in the freezer', action.orders);
      let orders = [];

      if (action.append) {
        orders = state.orderHistory || [];
        orders.push(action.orders);
      } else {
        orders = action.orders;
      }

      return {
        ...state,
        loading: false,
        orderHistory: orders
      }
    case 'TEST_FIELDS':
      console.log('tests received:', action.payload);
      return {
        ...state,
        testFields: action.payload
      }
    case 'NAV_HEADER':
      return {
        ...state,
        navHeader: action.payload
      }
    case 'SHOW_NAV':
      return {
        ...state, 
        showNav: action.payload
      }
    case 'UPDATE_LOOKUP_INFO':
      return {
        ...state,
        info: action.payload
      }
    case 'FILE_UPLOAD_SUCCESS':
      return {
        ...state,
        info: {
          ...state.info,
          files: state.info.files.concat(action.payload)
        }
      } 
    case 'DELETE_FILE_SUCCESS':
      return {
        ...state,
        info: {
          ...state.info,
          files: state.info.files.filter(f => f.id !== action.payload)
        }
      }
    default:
      return state
  }
}

export default rootReducer;
