import React from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TableSortLabel,
  Paper 
} from '@mui/material';
import ReportTest from '../report/report_components/ReportTest';
import ReportNote from '../report/report_components/ReportNote';
import { Chart } from 'chart.js/auto';

import '../styles/lookup.scss';
import 'materialize-css';


class AccountReviewModal extends React.Component {

  constructor() {
    super();
    this.state = {
      orderBy: 'sales',
      order: 'desc'
    };
    this.revenueChart = null;
    this.costChart = null;
  }

  handleRequestSort = (property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property
    });
  };

  getSortedData = (data) => {
    return [...data].sort((a, b) => {
      const aValue = a[this.state.orderBy] || '';
      const bValue = b[this.state.orderBy] || '';

      // Handle numeric fields
      if (['quantity', 'sales', 'cost'].includes(this.state.orderBy)) {
        return this.state.order === 'asc' 
          ? parseFloat(aValue || 0) - parseFloat(bValue || 0)
          : parseFloat(bValue || 0) - parseFloat(aValue || 0);
      }

      // Handle string fields
      return this.state.order === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });
  };

  createOrUpdateCharts() {
    const revenueCtx = document.getElementById('revenueBreakdownChart');
    const costCtx = document.getElementById('costBreakdownChart');
    
    if (!revenueCtx || !costCtx) {
      console.error('Chart canvas elements not found');
      return;
    }

    if (!this.props.data || !this.props.data.length) {
      console.error('No data available for charts');
      return;
    }

    try {
      if (this.revenueChart) this.revenueChart.destroy();
      if (this.costChart) this.costChart.destroy();

      // Filter data by year first
      const yearData = this.props.data.filter(d => d.year === this.props.year);
      
      if (yearData.length === 0) {
        console.error('No data found for selected year');
        return;
      }

      // Group by category (now using filtered data)
      const categoryTotals = yearData.reduce((acc, item) => {
        const category = item.item_category || 'Contract Billing';
        if (!acc[category]) {
          acc[category] = { revenue: 0, cost: 0 };
        }
        acc[category].revenue += (parseFloat(item.sales) || 0);
        acc[category].cost += (parseFloat(item.cost) || 0);
        return acc;
      }, {});

      const colors = [
        'rgba(54, 162, 235, 0.8)',   // blue
        'rgba(255, 159, 64, 0.8)',   // orange
        'rgba(75, 192, 192, 0.8)',   // teal
        'rgba(255, 99, 132, 0.8)',   // red
        'rgba(153, 102, 255, 0.8)',  // purple
        'rgba(255, 205, 86, 0.8)',   // yellow
      ];

      // Create categories and color mapping excluding Contract Billing
      const categories = Object.keys(categoryTotals).filter(cat => cat !== 'Contract Billing');
      const colorMap = categories.reduce((acc, category, index) => {
        acc[category] = colors[index % colors.length];
        return acc;
      }, {});
      // Add Contract Billing with its own color
      colorMap['Contract Billing'] = colors[colors.length - 1];

      const chartConfig = (title, data, labels, showLegend = false) => ({
        type: 'pie',
        data: {
          labels,
          datasets: [{
            data,
            backgroundColor: labels.map(label => colorMap[label]),
            borderWidth: 1,
            borderColor: '#fff'
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: 1,
          plugins: {
            title: {
              display: true,
              text: [
                title,
                `Total: $${data.reduce((a, b) => a + b, 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}`
              ],
              font: {
                size: 16,
                weight: 'bold'
              },
              padding: {top: 10, bottom: 10}
            },
            legend: {
              display: false, // Hide individual legends
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  const value = context.raw;
                  const total = context.dataset.data.reduce((a, b) => a + b, 0);
                  const percentage = ((value / total) * 100).toFixed(1);
                  return `$${value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} (${percentage}%)`;
                }
              }
            }
          },
          layout: {
            padding: {
              top: 10,
              bottom: 10,
              left: 10,
              right: 10
            }
          }
        }
      });

      const revenueLabels = Object.keys(categoryTotals);
      this.revenueChart = new Chart(revenueCtx, 
        chartConfig('Revenue Breakdown', 
          revenueLabels.map(cat => categoryTotals[cat].revenue),
          revenueLabels
        )
      );

      const costLabels = Object.keys(categoryTotals);
      this.costChart = new Chart(costCtx, 
        chartConfig('Cost Breakdown', 
          costLabels.map(cat => categoryTotals[cat].cost),
          costLabels
        )
      );

      // Create shared legend
      const legendContainer = document.getElementById('sharedLegendContainer');
      legendContainer.innerHTML = ''; // Clear existing legend

      const ul = document.createElement('ul');
      ul.className = 'shared-legend';
      ul.style.display = 'flex';
      ul.style.flexWrap = 'wrap';
      ul.style.justifyContent = 'center';
      ul.style.gap = '1rem';
      ul.style.padding = '0';
      ul.style.margin = '0';
      ul.style.listStyle = 'none';

      revenueLabels.forEach(label => {
        const li = document.createElement('li');
        
        const colorBox = document.createElement('span');
        colorBox.className = 'color-box';
        colorBox.style.backgroundColor = colorMap[label];

        const text = document.createElement('span');
        text.className = 'legend-text';
        text.textContent = label;

        li.appendChild(colorBox);
        li.appendChild(text);
        ul.appendChild(li);
      });

      legendContainer.appendChild(ul);

      // Add total profit display
      const totalRevenue = revenueLabels.reduce((sum, cat) => sum + categoryTotals[cat].revenue, 0);
      const totalCost = costLabels.reduce((sum, cat) => sum + categoryTotals[cat].cost, 0);
      const totalProfit = totalRevenue - totalCost;
      
      // Calculate previous year's profit
      const prevYear = parseInt(this.props.year) - 1;
      const prevYearData = this.props.data.filter(d => d.year === prevYear.toString());
      const prevYearTotals = prevYearData.reduce((acc, item) => {
        acc.revenue += (parseFloat(item.sales) || 0);
        acc.cost += (parseFloat(item.cost) || 0);
        return acc;
      }, { revenue: 0, cost: 0 });
      const prevYearProfit = prevYearTotals.revenue - prevYearTotals.cost;
      
      // Calculate percentage change
      const profitChange = prevYearProfit !== 0 
        ? ((totalProfit - prevYearProfit) / Math.abs(prevYearProfit)) * 100 
        : 0;
      
      const profitDiv = document.createElement('div');
      profitDiv.style.textAlign = 'center';
      profitDiv.style.marginTop = '1rem';
      profitDiv.style.fontWeight = 'bold';
      profitDiv.style.fontSize = '1.1em';
      profitDiv.style.color = totalProfit >= 0 ? '#2e7d32' : '#d32f2f';
      
      // Add year-over-year comparison
      const changeText = profitChange !== 0
        ? ` (${profitChange >= 0 ? '+' : ''}${profitChange.toFixed(1)}% vs ${prevYear})`
        : '';
      
      profitDiv.textContent = `Total Profit: $${totalProfit.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}${changeText}`;
      
      legendContainer.appendChild(profitDiv);
    } catch (error) {
      console.error('Error creating charts:', error);
    }
  }

  componentDidMount() {
    // Add a small delay to ensure DOM elements are ready
    setTimeout(() => {
      this.createOrUpdateCharts();
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data || prevProps.year !== this.props.year) {
      setTimeout(() => {
        this.createOrUpdateCharts();
      }, 100);
    }
  }

  componentWillUnmount() {
    if (this.revenueChart) this.revenueChart.destroy();
    if (this.costChart) this.costChart.destroy();
  }

  render() {
    if (!this.props.year) return null;

    const sortedData = this.getSortedData(this.props.data.filter(d => d.year === this.props.year));
    const prevYear = parseInt(this.props.year) - 1;
    const prevYearData = this.props.data.filter(d => d.year === prevYear.toString());
    
    // Group current year data by category
    const groupedData = sortedData.reduce((acc, item) => {
      const category = item.item_category || 'Contract Billing';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});

    // Group previous year data by category
    const prevYearGroupedData = prevYearData.reduce((acc, item) => {
      const category = item.item_category || 'Contract Billing';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});

    // Calculate totals for each category (current year)
    const categoryTotals = Object.entries(groupedData).reduce((acc, [category, items]) => {
      acc[category] = items.reduce((sum, item) => ({
        quantity: sum.quantity + (parseFloat(item.quantity) || 0),
        sales: sum.sales + (parseFloat(item.sales) || 0),
        cost: sum.cost + (parseFloat(item.cost) || 0)
      }), { quantity: 0, sales: 0, cost: 0 });
      return acc;
    }, {});

    // Calculate totals for each category (previous year)
    const prevYearCategoryTotals = Object.entries(prevYearGroupedData).reduce((acc, [category, items]) => {
      acc[category] = items.reduce((sum, item) => ({
        sales: sum.sales + (parseFloat(item.sales) || 0),
        cost: sum.cost + (parseFloat(item.cost) || 0)
      }), { sales: 0, cost: 0 });
      return acc;
    }, {});

    // Calculate grand totals
    const grandTotals = Object.values(categoryTotals).reduce((acc, totals) => ({
      quantity: acc.quantity + totals.quantity,
      sales: acc.sales + totals.sales,
      cost: acc.cost + totals.cost
    }), { quantity: 0, sales: 0, cost: 0 });

    const headCells = [
      { id: 'item_description', label: 'Item', numeric: false, width: '50%' },
      { id: 'item_category', label: 'Category', numeric: false, width: '10%' },
      { id: 'quantity', label: 'Qty.', numeric: true, width: '5%' },
      { id: 'sales', label: 'Sales', numeric: true, width: '15%' },
      { id: 'cost', label: 'Cost', numeric: true, width: '15%' },
    ];

    return (
      <div className="modal-backdrop" onClick={() => this.props.close()}>
        <div className="modal" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <h3>{this.props.year} Account Review</h3>
            
            <div className="chart-container">
              <div className="charts-row">
                <div className="chart-wrapper">
                  <canvas id="revenueBreakdownChart"></canvas>
                </div>
                <div className="chart-wrapper">
                  <canvas id="costBreakdownChart"></canvas>
                </div>
              </div>
              <div id="sharedLegendContainer" className="shared-legend"></div>
            </div>

            <TableContainer component={Paper} style={{ marginTop: '2rem' }}>
              {Object.entries(groupedData).map(([category, items]) => {
                // Calculate changes once for both cells
                const salesChange = prevYearCategoryTotals[category] ? 
                  ((categoryTotals[category].sales - prevYearCategoryTotals[category].sales) / 
                  Math.abs(prevYearCategoryTotals[category].sales) * 100) : null;
                
                const costChange = prevYearCategoryTotals[category] ? 
                  ((categoryTotals[category].cost - prevYearCategoryTotals[category].cost) / 
                  Math.abs(prevYearCategoryTotals[category].cost) * 100) : null;

                return (
                  <div key={category} style={{ marginBottom: '2rem' }}>
                    <h5 style={{ margin: '1rem' }}>{category}</h5>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          {headCells.map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              align={headCell.numeric ? 'right' : 'left'}
                              sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                              style={{ width: headCell.width }}
                            >
                              <TableSortLabel
                                active={this.state.orderBy === headCell.id}
                                direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                onClick={() => this.handleRequestSort(headCell.id)}
                              >
                                <strong>{headCell.label}</strong>
                              </TableSortLabel>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ width: '45%' }}>{row.item_description || 'N/A'}</TableCell>
                            <TableCell style={{ width: '15%' }}>{row.item_category || 'Contract Billing'}</TableCell>
                            <TableCell style={{ width: '10%' }} align="right">
                              {parseFloat(row.quantity || 0).toLocaleString()}
                            </TableCell>
                            <TableCell style={{ width: '15%' }} align="right">
                              ${parseFloat(row.sales || 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </TableCell>
                            <TableCell style={{ width: '15%' }} align="right">
                              ${parseFloat(row.cost || 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>Category Total</TableCell>
                          <TableCell align="right" style={{ fontWeight: 'bold' }}>
                            {categoryTotals[category].quantity.toLocaleString()}
                          </TableCell>
                          <TableCell align="right" style={{ fontWeight: 'bold', color: '#2e7d32' }}>
                            ${categoryTotals[category].sales.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                          </TableCell>
                          <TableCell align="right" style={{ fontWeight: 'bold', color: '#d32f2f' }}>
                            ${categoryTotals[category].cost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2} style={{ fontStyle: 'italic' }}>vs Previous Year</TableCell>
                          <TableCell align="right">-</TableCell>
                          <TableCell align="right" style={{ 
                              fontStyle: 'italic',
                              color: salesChange === null || isNaN(salesChange) || !isFinite(salesChange) ? 'inherit' :
                                salesChange > 0 ? '#2e7d32' : salesChange < 0 ? '#d32f2f' : 'inherit'
                            }}>
                            {salesChange === null || isNaN(salesChange) || !isFinite(salesChange) ? '–' : 
                              `${salesChange >= 0 ? '+' : ''}${salesChange.toFixed(1)}%`}
                          </TableCell>
                          <TableCell align="right" style={{ 
                              fontStyle: 'italic',
                              color: costChange === null || isNaN(costChange) || !isFinite(costChange) ? 'inherit' :
                                costChange > 0 ? '#d32f2f' : costChange < 0 ? '#2e7d32' : 'inherit'
                            }}>
                            {costChange === null || isNaN(costChange) || !isFinite(costChange) ? '–' : 
                              `${costChange >= 0 ? '+' : ''}${costChange.toFixed(1)}%`}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                );
              })}
              
              {/* Grand Totals */}
              <div style={{ margin: '1rem' }}>
                <h4>Grand Totals</h4>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>Total</TableCell>
                      <TableCell align="right" style={{ fontWeight: 'bold' }}>
                        {grandTotals.quantity.toLocaleString()}
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: 'bold', color: '#2e7d32' }}>
                        ${grandTotals.sales.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: 'bold', color: '#d32f2f' }}>
                        ${grandTotals.cost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} style={{ fontStyle: 'italic' }}>vs Previous Year</TableCell>
                      <TableCell align="right">-</TableCell>
                      <TableCell align="right" style={{ 
                          fontStyle: 'italic',
                          color: (() => {
                            const prevYearSales = prevYearData.reduce((sum, item) => sum + (parseFloat(item.sales) || 0), 0);
                            const salesChange = prevYearSales ? ((grandTotals.sales - prevYearSales) / Math.abs(prevYearSales) * 100) : null;
                            return salesChange === null || isNaN(salesChange) || !isFinite(salesChange) ? 'inherit' :
                              salesChange > 0 ? '#2e7d32' : salesChange < 0 ? '#d32f2f' : 'inherit';
                          })()
                        }}>
                        {(() => {
                          const prevYearSales = prevYearData.reduce((sum, item) => sum + (parseFloat(item.sales) || 0), 0);
                          const salesChange = prevYearSales ? ((grandTotals.sales - prevYearSales) / Math.abs(prevYearSales) * 100) : null;
                          return salesChange === null || isNaN(salesChange) || !isFinite(salesChange) ? '–' : 
                            `${salesChange >= 0 ? '+' : ''}${salesChange.toFixed(1)}%`;
                        })()}
                      </TableCell>
                      <TableCell align="right" style={{ 
                          fontStyle: 'italic',
                          color: (() => {
                            const prevYearCost = prevYearData.reduce((sum, item) => sum + (parseFloat(item.cost) || 0), 0);
                            const costChange = prevYearCost ? ((grandTotals.cost - prevYearCost) / Math.abs(prevYearCost) * 100) : null;
                            return costChange === null || isNaN(costChange) || !isFinite(costChange) ? 'inherit' :
                              costChange > 0 ? '#d32f2f' : costChange < 0 ? '#2e7d32' : 'inherit';
                          })()
                        }}>
                        {(() => {
                          const prevYearCost = prevYearData.reduce((sum, item) => sum + (parseFloat(item.cost) || 0), 0);
                          const costChange = prevYearCost ? ((grandTotals.cost - prevYearCost) / Math.abs(prevYearCost) * 100) : null;
                          return costChange === null || isNaN(costChange) || !isFinite(costChange) ? '–' : 
                            `${costChange >= 0 ? '+' : ''}${costChange.toFixed(1)}%`;
                        })()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }
}
 export default AccountReviewModal;
