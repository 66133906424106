import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions'; //Import your actions
// import { Autocomplete } from 'react-materialize';
import Autocomplete from '../../components/Autocomplete';
import 'materialize-css';
import '../../styles/report.scss';


class AddReportTest extends React.Component {

  formatTests() {
    return this.props.tests.map(test => ({
      id: test.id,
      label: `${test.test_name}`,
      desc: `${test.unit_of_measure}`
    }))
  }

  compHasTest(testId) {
    let tests = Object.entries(this.props.report.components[this.props.component.id].tests).map(t => t[1]);
    let matching = tests.filter(t => t.testId === testId);
    return matching.length > 0 ? matching[0] : false;
  }

  addTest(testid) {
    let componentTestId = Date.now();

    let selectedTest = this.props.tests.find(test => test.id === testid);
    console.log('ADDTEST______', selectedTest, testid, this.props.tests)
    let matchingTest = this.compHasTest(selectedTest.id);

    if (matchingTest) {
      if (matchingTest.deleted) {

        delete matchingTest.deleted; // remove deleted property

        this.props.updateReport( // update it
          this.buildScaffold(matchingTest),
          {fridge: true, freezer: this.props.authToken},
          () => this.props.close()
        );
      }
      return this.props.close()
    } else {
      let testObj = {
        ...selectedTest,
        id: componentTestId,
        testId: selectedTest.id,
        result: null,
        iAm: 'test',
        breadcrumbs: (
          this.props.component.id === '-1' ? 
          `${this.props.report.id}.components.log.nodes.${new Date().toLocaleString().split(',')[0]}.${componentTestId}` :
          `${this.props.report.id}.components.${this.props.component.id}.tests.${componentTestId}`
        )
      };
  
      this.props.updateReport(
        this.buildScaffold(testObj),
        {fridge: true, freezer: this.props.authToken},
        () => this.props.close()
      );
    }
  }

  buildScaffold(test) {
    let breadcrumbs = test.breadcrumbs.split('.');
    let reportId = breadcrumbs.reverse().pop();
    let reportScaffold = {
      id: reportId,
      timestamp: Math.floor(Date.now()/1000),
      ...breadcrumbs.reduce((val, key) => ({[key]: val}), {...test})
    };
   
    return reportScaffold;
  }

  render() {
    if (this.props.component === null) {return null}
    return (
      <div class="report-modal" onClick={() => this.props.close()}>
        <Autocomplete
          list={this.formatTests()}
          onSelect={(testObj) => this.addTest(testObj.id)}
          placeholder="Search for a report test"
        />
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    report: state.report,
    tests: state.testFields
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddReportTest);
