import React from 'react';

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions

import '../styles/lookup.scss';
// import 'materialize-css';
import MapView from './MapView';
import ServiceLogModal from './ServiceLogModal';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

class Product extends React.Component {
  state = {
    page: 0,
    rowsPerPage: 15,
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  render() {
    return this.props.info?.type === 'product' ? (
      <div className="info-container">
        <div className="product-container">
          <img src={this.props.info.img_link} className={this.props.info.img_link ? "product-image" : "product-image empty"} alt="product" />
          <div className="product-information">
            <span className="model">{this.props.info.model}</span>
            <span>Vendor: <b>{this.props.info.vendor.name}</b></span>
            <span className="description">{this.props.info.description}</span>
            
            <a className="button sds" href={this.props.info.sds_link}>
              Safety Datasheet
              <WarningAmberIcon className="sds-icon" />
            </a>

            {this.props.info.variants.length ? (
              <div className="options-container">
                Available Options: 
                {this.props.info.variants.map(variant => (
                  <div className="product-option">{variant.code}</div>
                ))}
              </div>
            ) : null}
            
            {this.props.info.size_options?.replace(/\[|\]/g, '').split(',').length ? (
              <div className="options-container">
                Available Sizes ({this.props.info.size_unit}):
                {this.props.info.size_options?.replace(/\[|\]/g, '').split(',').map(size => (
                  <div className="product-option">{size}</div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      
        <div id="product-orders" className="info-card expandable">
          <span className="card-header">
            <b>{this.props.info.orders.length}</b>
            <span>
              Order{this.props.info.orders.length === 1 ? '' : 's'} placed since {new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toLocaleDateString()}
            </span>

            <div className="card-icon-container">
              <ShoppingCartIcon className="card-icon" />
            </div>
          </span>

          <div className="card-content">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" size="medium">
                <TableBody>
                  {this.props.info.orders
                    .sort((a, b) => new Date(b.submitted) - new Date(a.submitted)) // Sort orders by submitted date
                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={'system-'+row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        onClick={() => window.open(row.url, '_blank', 'noopener,noreferrer')}
                      >
                        <TableCell align='left'>Order for: <b>{row.recipient}</b></TableCell>
                        <TableCell align='left'>Placed by: <b>{row.placed_by}</b></TableCell>
                        <TableCell align='right'>Submitted: <b>{row.submitted}</b></TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={this.props.info.orders.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    ) : null
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    info: state.info
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Product);
